import { API_URL } from "@/config";
import axios from "axios";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type UniverseOption = {
  value: string;
  label: string;
  uList: Array<string>;
};

export type ScannerStrategyOption = {
  value: number;
  label: string;
  symbol: string;
  canvas_data: string;
  timeFrame: string;
  keygen: string;
  strategy_keygen: string;
};

type PatternUniverseContextType = {
  universes: UniverseOption[];
  userUniverses: UniverseOption[];
  scannerStrategies: ScannerStrategyOption[];
  userId: string;
  refetchUniverse: (forceRefetch?: boolean, userId?: string) => void;
  refetchScannerStrategies: (forceRefetch?: boolean, userId?: string) => void;
};

export type Strategy = {
  id: number;
  keygen: string;
  strategy_name: string;
  symbol: string;
  is_deleted: number;
  timeframe: string;
  strategy_category: {
    id: number;
    label: string;
    description: string;
    is_valid: string;
  };
  add_ons: string;
  is_copied: number;
  is_notification: number;
  backtest_type: string;
  strategy_data: string;
  canvas_data: string;
  created_at: string;
};

export type UniverseResponse = {
  keygen: string;
  meta: { name: string };
  universe_list: Array<string>;
}[];

export type ScannerStrategyResponse = {
  meta: {};
  keygen: string;
  strategy: Strategy;
}[];

const init = {
  universes: [],
  userUniverses: [],
  scannerStrategies: [],
  userId: "",
  refetchUniverse: () => null,
  refetchScannerStrategies: () => null,
};

export const PatternUniverseContext =
  createContext<PatternUniverseContextType>(init);

export const PatternUniverseContextProvider = (props: {
  children: ReactNode;
}) => {
  const [universes, setUniverse] = useState<UniverseOption[]>([]);
  const [userUniverses, setUserUniverses] = useState<UniverseOption[]>([]);
  const [scannerStrategies, setScannerStrategies] = useState<
    ScannerStrategyOption[]
  >([]);

  const userId = useMemo(() => {
    if (
      typeof window !== "undefined" &&
      typeof window.localStorage !== "undefined"
    ) {
      const u = JSON.parse(localStorage.getItem("user-info")!);
      return u?.user_id;
    }
  }, []);

  const fetchUniverse = useCallback(async (userId: string | null = null) => {
    try {
      const url = new URL("get_market_scanner_universes", API_URL);
      url.searchParams.append("user_id", `${userId ? userId : "4VXr2i"}`);
      const res = await axios.get(url.toString());
      const data: UniverseResponse = res?.data;
      if (res.status == 200) {
        if (userId)
          setUserUniverses(
            data?.map((list) => {
              return {
                label: list?.meta?.name,
                value: list?.keygen,
                uList: list?.universe_list,
              };
            })
          );
        else
          setUniverse(
            data?.map((list) => {
              return {
                label: list?.meta?.name,
                value: list?.keygen,
                uList: list?.universe_list,
              };
            })
          );
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchScannerStrategies = useCallback(
    async (userId: string | null = null) => {
      if (!userId) return;
      try {
        const url = new URL("get_market_scanner_strategies", API_URL);
        url.searchParams.append("user_id", userId);
        const res = await axios.get(url.toString());
        const data: ScannerStrategyResponse = res?.data;
        if (res.status == 200) {
          setScannerStrategies(
            data?.map((list) => {
              return {
                label: list?.strategy.strategy_name,
                value: list?.strategy.id,
                symbol: list?.strategy.symbol,
                canvas_data: list?.strategy.canvas_data,
                timeFrame: list?.strategy.timeframe,
                keygen: list?.keygen,
                strategy_keygen: list?.strategy.keygen,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const refetchUniverse = useCallback(
    (forceRefetch: boolean = false, userId: string | null = null) => {
      if (userId && (!userUniverses.length || forceRefetch))
        fetchUniverse(userId);
      else if (!universes.length || forceRefetch) fetchUniverse();
    },
    [universes, fetchUniverse]
  );

  const refetchScannerStrategies = useCallback(
    (forceRefetch: boolean = false, userId: string | null = null) => {
      if (!scannerStrategies.length || forceRefetch)
        fetchScannerStrategies(userId);
    },
    [scannerStrategies, fetchScannerStrategies]
  );

  return (
    <PatternUniverseContext.Provider
      value={{
        universes,
        scannerStrategies,
        userUniverses,
        refetchUniverse,
        refetchScannerStrategies,
        userId,
      }}
    >
      {props.children}
    </PatternUniverseContext.Provider>
  );
};

export const usePatternUniverses = () => useContext(PatternUniverseContext);
